// src/components/JobCard.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./NewJobCard.css";
import { parseISO, formatDistanceToNow } from "date-fns";

const JobCard = ({ job }) => {
  const [hasTriedFallback, setHasTriedFallback] = useState(false);

  const BASE_URL = process.env.NODE_ENV
    ? "https://api.thejobslist.io"
    : "http://localhost:3001";

  const PartitionCircle = () => (
    <div className="partition-circle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2"
        height="2"
        viewBox="0 0 2 2"
        fill="none"
      >
        <circle cx="1" cy="1" r="1" fill="#141414" fill-opacity="0.7" />
      </svg>
    </div>
  );

  const handleImageError = (e) => {
    if (!hasTriedFallback) {
      e.target.src = `${BASE_URL}/public/company/logo/${job.company}`;
      setHasTriedFallback(true);
    }
  };

  return (
    <div className="card">
      <div className="job-logo">
        <img
          src={
            "https://logo.clearbit.com/" +
            `https://${(job?.company)
              .replace(/ /g, "")
              .toLowerCase()}.com?size=256`
          }
          alt={`${job.company} logo`}
          onError={handleImageError}
        />
      </div>
      <div className="post-details">
        <Link to={`/job/${job.jobAlias}`} className="jobLink">
          <div className="post-company">{job?.company}</div>
          <div className="post-position">{job?.title}</div>
          <div className="post-specs">
            <div className="location-time-wrapper">
              <div className="icon-text-wrapper location">
                <span className="material-symbols-outlined">pin_drop</span>{" "}
                {job?.location}
              </div>
              <div className="icon-text-wrapper created-at">
                <span className="material-symbols-outlined">
                  calendar_clock
                </span>{" "}
                {formatDistanceToNow(parseISO(job.createdAt), {
                  addSuffix: true,
                }).replace("about ", "")}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default JobCard;
