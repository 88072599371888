// src/services/api.js
import { isValidJobData } from "../validators/jobValidator";
import Job from "../models/job";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.thejobslist.io"
    : "http://localhost:3500";

export const fetchJobs = async (params) => {
  let queryParams = `?page=${params.page}`;

  if (params.searchTerm) {
    queryParams += `&searchTerm=${params.searchTerm}`;
  }

  const response = await fetch(`${BASE_URL}/get-jobs${queryParams}`);
  let data = await response.json();

  return data.data;

  //   if (!Array.isArray(data)) throw new Error("Invalid data from server");

  //   const validJobs = data.filter(isValidJobData);
  //   return validJobs.map((jobData) => new Job(jobData));
};

export const fetchAJob = async (jobAlias) => {
  const response = await fetch(`${BASE_URL}/job/${jobAlias}`);
  let data = await response.json();

  console.log("Response: ", data);

  return data.data;

  //   if (!Array.isArray(data)) throw new Error("Invalid data from server");

  //   const validJobs = data.filter(isValidJobData);
  //   return validJobs.map((jobData) => new Job(jobData));
};

export const subscribeEmailApi = async (data) => {
  console.log("subscribing with data: ", data);
  const response = await fetch(`${BASE_URL}/subscribe/new`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  console.log("response: ", response.status, response.ok);
  return response; // Return the entire response object
};

export const getSitemapXml = async () => {
  return await fetch(BASE_URL + "/sitemap.xml");
};

export const saveJobTrackings = async (data) => {
  console.log("subscribing with data: ", data);
  const resposne = await fetch(`${BASE_URL}/job-opened`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      url: data.url,
      title: data.title,
      location: data.location,
      company: data.company,
    }),
  });
  console.log("response: ", resposne.status, resposne.ok);
  return true;
};

export const filterJobs = async (data) => {
  console.log("filterJobs with data: ", data);
  const resposne = await fetch(`${BASE_URL}/filter-jobs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(data),
  });
  console.log("response: ", resposne.status, resposne.ok);
  let jobs = await resposne.json();

  return jobs.data;
};

export const fetchFilterOptions = async () => {
  const response = await fetch(`${BASE_URL}/get-filter-options`);
  let data = await response.json();

  console.log("Response: ", data);

  return data;
};

export const uploadFile = async ({ file, email }) => {
  console.log("upload file called: ", file);
  if (!file) return;

  const formData = new FormData();
  formData.append("resume", file);
  if (email) formData.append("email", email);

  try {
    const response = await fetch(`${BASE_URL}/api/upload`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      return response;
      // alert("Upload successful");
    } else {
      // alert("Upload failed");
    }
  } catch (error) {
    console.error("There was an error uploading the file.", error);
  }
};
