// src/components/Footer/index.js

import React from "react";
import { Link } from "react-router-dom";
import "./footer.css"; // Link to the new CSS file

const Footer = () => {
  return (
    <footer className="footer-container">
      <hr className="footer-divider" />
      <div className="footer-links">
        <Link to="/contact-us">Contact Us</Link>
        <span>|</span>
        <Link to="/terms-and-conditions">Terms & Conditions</Link>
        <span>|</span>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <p>© 2023 Jobs List.</p>
    </footer>
  );
};

export default Footer;
