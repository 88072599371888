import React, { useState } from "react";
import "./ResumeUpload.css"; // If you want to style it separately
import { uploadFile } from "../../services/api";

const ResumeUpload = ({ onUploadSuccess, onUploadFailure }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    // console.log("selectedFile.mimetype: ", selectedFile);
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        // File is more than 5MB
        setError("File size should be less than 5MB");
        setFile(null);
      } else if (
        ![
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(selectedFile.type)
      ) {
        setError("Only PDF and Word documents are allowed");
        setFile(null);
      } else {
        setError("");
        setFile(selectedFile);
        await uploadSelectedFile(selectedFile);
      }
    }
  };

  const uploadSelectedFile = async (file) => {
    try {
      const response = await uploadFile({file});
      if (response.status === 200 || response.status === 201) {
        onUploadSuccess(response.data);
      } else {
        onUploadFailure(response.data);
      }
    } catch (error) {
      onUploadFailure(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      try {
        const response = await uploadFile({file}); // Make sure uploadFile returns a promise
        if (response.status === 200 || response.status === 201) {
          onUploadSuccess(response.data); // Assuming response.data holds relevant information
        } else {
          onUploadFailure(response.data); // Pass the error message or error details
        }
      } catch (error) {
        onUploadFailure(error.message);
      }
    }
  };

  return (
    <div className="resume-container">
      <div className="filter-heading">🚀 Get noticed faster</div>
      <div className="post-description">
        Upload your resume and increase your chances of getting noticed by top
        employers.
      </div>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit}>
        <label className="large-primar-btn">
          Resume Upload
          <input
            type="file"
            style={{ display: "none" }} // hide the default input
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx" // Accept only PDF and Word files
          />
        </label>
      </form>
    </div>
  );
};

export default ResumeUpload;
