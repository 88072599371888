// src/views/JobPage.js

import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import JobDetails from "../components/JobDetails";
import { fetchAJob } from "../services/api";
import "./JobPage.css";

const JobPage = (props) => {
  const [job, setJob] = useState(null);
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const getJob = async () => {
      try {
        console.log("jobId: ", location.pathname.split("/")[2]);
        const job = await fetchAJob(location.pathname.split("/")[2]);

        setJob(job);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    getJob();
  }, [id]);

  if (!job) return <div>Loading...</div>;

  return (
    <div className="job-page">
      <NavBar />
      <JobDetails job={job} />
      <Footer />
    </div>
  );
};

export default JobPage;
