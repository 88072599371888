// src/views/TermsAndConditions.js
import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import "./../styles/CommonPages.css";

const TermsAndConditions = () => {
  return (
    <div className="page-container">
      <NavBar />

      <h2>Terms and Conditions</h2>
      <p>
        Welcome to TheJobsList.io. These terms and conditions outline the rules
        and regulations for the use of TheJobsList's website.
      </p>
      <p>
        By accessing this website, we assume you accept these terms and
        conditions in full. Do not continue to use TheJobsList's website if you
        do not accept all of the terms and conditions stated on this page.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        TheJobsList.io is not responsible for any damages or losses related to
        your use of the service. We don’t become involved in disputes between
        users, or between users and any third party relating to the use of the
        services.
      </p>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
