// src/components/Sitemap.js
import React, { useEffect, useState } from "react";
import { getSitemapXml } from "./../../services/api";

const Sitemap = () => {
  const [xmlContent, setXmlContent] = useState("");

  useEffect(() => {
    // Fetch the sitemap.xml from your backend
    getSitemapXml()
      .then((response) => response.text())
      .then((data) => {
        setXmlContent(data);
      })
      .catch((err) => {
        console.error("Error fetching the sitemap:", err);
      });
  }, []);

  return <pre style={{ whiteSpace: "pre-wrap" }}>{xmlContent}</pre>;
};

export default Sitemap;
