import React, { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import Modal from "./../Modal/Modal"; // Assuming you have a Modal component in the same directory
import JobAlertForm from "./../JobAlertForm";
import MockInterviewForm from "./../MockInterviewForm";

const NavBar = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModalWithContent = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const handleJobAlertClick = () => {
    openModalWithContent(<JobAlertForm onClose={closeModal} />);
  };

  const handleMockInterviewClick = () => {
    openModalWithContent(<MockInterviewForm onClose={closeModal} />);
  };

  const handlePostJobClick = () => {
    const content = <div>{/* Your Post a Job form here */}</div>;
    openModalWithContent(content);
  };

  const handleFeedbackClick = () => {
    const content = <div>{/* Your Feedback form here */}</div>;
    openModalWithContent(content);
  };

  return (
    <div class="navbar">
      <div class="navbar-logo">
        <Link to={`/`} className="no-decoration-brand">
          <img src="/logo.svg" alt="JOBS LIST" width="30" height="30" />
          <div className="company-name-logo">The Jobs List</div>
        </Link>
      </div>
      <div class="navbar-menu">
        <div class="menu-icon" id="menu-icon">
          <span className={`material-symbols-outlined `}>menu</span>
        </div>
        <ul class="nav-links" id="nav-links">
          <li onClick={handleJobAlertClick}>Get New Job Alerts</li>
          <li onClick={handleMockInterviewClick}>Mock Interviews</li>
        </ul>
      </div>

      <Modal show={showModal} modalClosed={closeModal}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default NavBar;
