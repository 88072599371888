import React from 'react'
import "../Headlines.css";

const HomeMain = () => {
  return (
    <div className='home-main-headling-container'>
      <div className='home-main-headling'>
        Find your <span className='blue-patch'>new job</span> today
      </div>
      <div className='home-main-sub-heading'>
        Thousands of jobs in the computer, engineering and technology sectors are waiting for you.
      </div>
    </div>
  )
}

export default HomeMain