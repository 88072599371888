import React, { useState } from "react";
import { subscribeEmailApi } from "../../services/api";
import { Link, useLocation } from "react-router-dom";

const JobAlertForm = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState(null); // For storing feedback
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission

  const url = useLocation(); // This will get the current page URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set to true once submission starts

    try {
      const response = await subscribeEmailApi({
        email,
        url,
        navBar: true,
        name,
      });
      if (response) {
        localStorage.setItem("userEmail", email);
        setFeedback({
          type: "success",
          message: "We'll email you to confirm soon.",
        });
        setTimeout(onClose, 2000); // Optionally, close the modal after a 2-second delay
      } else {
        setFeedback({
          type: "error",
          message: "Subscription failed.",
        });
      }
    } catch (error) {
      setFeedback({
        type: "error",
        message: "An error occurred. Please try again.",
      });
    } finally {
      setIsSubmitting(false); // Reset submission state regardless of response outcome
    }
  };

  return (
    <div className="modal-form">
      <h2>Stay Ahead with Job Alerts!</h2>
      <p>
        Be the first to get notified about new job opportunities tailored for
        you. Enter your email and let opportunities knock on your door.
      </p>
      {feedback && (
        <div className={`feedback ${feedback.type}`}>{feedback.message}</div>
      )}

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your Name"
        />

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Join now"}
        </button>
      </form>
      {/* <button onClick={onClose}>Close</button> */}
    </div>
  );
};

export default JobAlertForm;
