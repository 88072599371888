// src/components/JobDetails/index.js

import React, { useState, useEffect } from "react";
import { subscribeEmailApi } from "./../../services/api";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import ResumeUploadWhileApply from "../ResumeUploadWhileApply";

const JobDetails = ({ job }) => {
  const handleApplyClick = () => {
    if (savedEmail) {
      // window.open(job.applyLink, "_blank");
      setShowPopup(true);
    } else {
      setShowPopup(true);
    }
  };
  const savedEmail = localStorage.getItem("userEmail");
  const [hasUnlockedEmails, setHasUnlockedEmails] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState(savedEmail || "");
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [resume, setResume] = useState(null);
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [message, setMessage] = useState("");

  const location = useLocation(); // This will get the current page URL

  const timeSince = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);

    const minuteInSeconds = 60;
    const hourInSeconds = 3600;
    const dayInSeconds = 86400;
    const weekInSeconds = 604800;
    const monthInSeconds = 2592000;
    const yearInSeconds = 31536000;

    if (seconds < minuteInSeconds) return `just now`;
    if (seconds < hourInSeconds)
      return `${Math.floor(seconds / minuteInSeconds)} mins ago`;
    if (seconds < dayInSeconds)
      return `${Math.floor(seconds / hourInSeconds)} hours ago`;
    if (seconds < weekInSeconds)
      return `${Math.floor(seconds / dayInSeconds)} days ago`;
    if (seconds < monthInSeconds)
      return `${Math.floor(seconds / weekInSeconds)} weeks ago`;
    if (seconds < yearInSeconds)
      return `${Math.floor(seconds / monthInSeconds)} months ago`;

    return `over a year ago`;
  };

  let jobDesc =
    job.descriptionHtml.length < 100 ? job.description : job.descriptionHtml;

  let logo = job.logoUrl || job.company;

  const handleUnlock = async () => {
    try {
      let response = await subscribeEmailApi({
        email,
        jobId: job.id,
        unlockedRecruiterEmail: true,
      });
      if (response.ok) {
        localStorage.setItem("userEmail", email);
        setHasUnlockedEmails(true);
      } else {
        const errorData = await response.json();
        const errorMsg = errorData?.message || "An error occurred.";
        setMessage(errorMsg + " Please try again.");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setMessage("An unexpected error occurred. Please try again.");
    }
  };

  const handleSubmit = async () => {
    if (!email.trim()) {
      setMessage("Please enter a valid email address.");
      return;
    }

    setSubmitting(true);

    try {
      let response = await subscribeEmailApi({ email, jobId: job.id });

      if (response.ok) {
        localStorage.setItem("userEmail", email);
        setSubmitSuccess(true);
        setTimeout(() => {
          window.open(job.applyLink, "_blank");
        }, 500);
        setSubmitting(false);
      } else {
        // Extract error message if it exists in the response body
        const errorData = await response.json();
        const errorMsg = errorData?.message || "An error occurred.";
        setMessage(errorMsg + " Please try again.");
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setMessage("An unexpected error occurred. Please try again.");
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(job.ldjson);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [job.ldjson]);

  return (
    <div className="job-details-main">
      <Helmet>
        <title>
          {job.title} at {job.company} - Jobs List
        </title>

        <meta
          name="description"
          content={`Apply for ${job.title} at ${job.company}.`}
        />
        <link
          rel="canonical"
          href={window.location.origin + location.pathname}
        />
      </Helmet>

      {/* <div className="job-logo">
        <img src={logo} alt={`${job.company} Logo`} />
      </div> */}

      <div className="job-container">
        <h1 className="job-title">
          {job.title}, {job.company}
        </h1>
        <div className="posted-date">
          ( Posted {timeSince(new Date(job.createdAt))} )
        </div>
        <div className="job-location">
          <p>{job.location}</p>
        </div>

        {job.recruiterEmails && job.recruiterEmails.length > 0 && (
          <div className="recruiters-section">
            <h2>Exclusive: Connect Directly with Recruiters!</h2>
            <p>
              This information is exclusively available on our platform. Get to
              know the people who'll be reviewing your application:
            </p>

            {hasUnlockedEmails ? (
              <ul>
                {job.recruiterEmails.map((email, index) => (
                  <li key={index}>{email}</li>
                ))}
              </ul>
            ) : (
              <>
                <input
                  type="email"
                  placeholder="Unlock with your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {message && <div className="error-message">{message}</div>}

                <button onClick={handleUnlock} className="unlock-button">
                  Subscribe & Unlock
                </button>
              </>
            )}
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: jobDesc }}></div>

        {job.possibleInterviewQuestions &&
          job.possibleInterviewQuestions.length > 0 && (
            <div className="interview-questions-section">
              <h2>
                <span className="star-icon">🌟</span>
                Possible Interview Questions
              </h2>
              <ul>
                {job.possibleInterviewQuestions.map((question, index) => (
                  <li key={index} className="question-card">
                    <p>{question}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}

        <button onClick={handleApplyClick} className="apply-button">
          Apply
        </button>

        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <span className="close-icon" onClick={() => setShowPopup(false)}>
                X
              </span>
              <h2>Step Up Your Job Search & Apply!</h2>
              {submitSuccess ? (
                <p>
                  Awesome! Get ready for tailored job alerts in your inbox.
                  Redirecting you to the application now...
                </p>
              ) : (
                <>
                  <p>Subscribe for job alerts and mock interviews</p>
                  <input
                    type="email"
                    placeholder="Your best email for job insights"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <ResumeUploadWhileApply
                    email={email}
                    onUploadSuccess={(data) => {
                      // Maybe update the UI to show success, or close the popup.
                      console.log(data);
                    }}
                    onUploadFailure={(error) => {
                      // Handle the failure, maybe show a notification.
                      console.error(error);
                    }}
                  />
                  {message && <div className="error-message">{message}</div>}

                  <button
                    className="subscribe-button"
                    onClick={handleSubmit}
                    disabled={submitting}
                  >
                    {submitting
                      ? "Setting You Up."
                      : "Subscribe & Proceed to Apply"}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetails;
