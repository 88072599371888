import React, { useEffect, useRef, useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import JobCard from "../components/JobCard";
import NewJobCard from "../components/NewJobCard/NewJobCard";
import { subscribeEmailApi } from "../services/api";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

import Pagination from "../components/Pagination"; // Import the Pagination component
import { fetchJobs, filterJobs, fetchFilterOptions } from "../services/api";
import "./HomePage.css";
import Select from "react-select";
import MetricsBox from "../components/MetricsBox"; // Import the MetricsBox
import HomeMain from "../components/Headlines/HomeMain";
import { format, parseISO, formatDistanceToNow } from "date-fns";
import Checkbox from "../components/Checkbox";
import ResumeUpload from "../components/ResumeUpload";

const HomePage = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [viewAllSkills, setViewAllSkills] = useState(false);
  const [filters, setFilters] = useState({
    countries: [],
    skills: [],
    tags: [],
  });
  const [countryOptions, setCountryOptions] = useState([
    { value: "US", label: "United States" },
  ]);
  const [skillOptions, setSkillOptions] = useState([
    { value: "javascript", label: "JavaScript" },
  ]);
  const [tagOptions, setTagOptions] = useState([
    { value: "fulltime", label: "Full Time" },
  ]);
  const [imageError, setImageError] = useState([]);
  const filterRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState(null);
  const url = useLocation();

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await subscribeEmailApi({
        email,
        url: url.pathname,
        navBar: true,
      });
      if (response) {
        localStorage.setItem("userEmail", email);
        setFeedback({
          type: "success",
          message: "We'll email you to confirm soon.",
        });
      } else {
        setFeedback({
          type: "error",
          message: "Subscription failed.",
        });
      }
    } catch (error) {
      setFeedback({
        type: "error",
        message: "An error occurred. Please try again.",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the height of the viewport
      const viewportHeight = window.innerHeight;

      // Calculate the scroll position
      const scrollPosition = window.scrollY;

      // Calculate 1.25 times the viewport height
      const threshold = 1.25 * viewportHeight;

      // Check if the user has scrolled above 1.25 times the height
      if (scrollPosition > threshold) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleFilters = () => {
    const filterElement = filterRef.current;

    if (filterElement) {
      if (filterElement.style.display === "none") {
        filterElement.style.display = "block";
        filterElement.style.opacity = 1;
      } else {
        filterElement.style.display = "none";
        filterElement.style.opacity = 0;
      }
    }
  };

  async function getFilterOptions() {
    if (countryOptions && countryOptions.length < 2) {
      let data = await fetchFilterOptions();

      setCountryOptions(data.maps.countryOptions);
      setSkillOptions(data.maps.skillsOptions);
      setTagOptions(data.maps.tagsOptions);
    }
  }

  useEffect(() => {
    getFilterOptions();
  }, []); // Empty dependency array ensures it runs once when component mounts

  const handleSearchChange = (e) => {
    const value = e.target.value;
    // Only allow alphabets and limit to 24 characters
    if (/^[a-zA-Z]*$/g.test(value) && value.length <= 24) {
      setSearchTerm(value);
      getJobs(value);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    if (searchTerm) {
      setCurrentPage(1); // Reset the current page
      // Assuming you have a function to make API call
      getJobs(searchTerm);
    }
  };

  const getJobs = async (searchTerm) => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchJobs({
        page: currentPage,
        searchTerm: searchTerm,
      });
      setJobs(data);
      setImageError(Array(data?.length).fill(false));
      setTotalPages(Math.ceil(data.totalCount / 50));
    } catch (error) {
      setError("There was an error fetching jobs.");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (name, selectedOptions) => {
    console.log("selectedOptions: ", selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setFilters((prevFilters) => ({ ...prevFilters, [name]: selectedValues }));
    setCurrentPage(1); // Reset the current page
  };

  async function applyFilters(filters) {
    // const applyFilters = async (filters) => {
    setLoading(true);
    setError(null);
    try {
      const data = await filterJobs({
        page: currentPage,
        ...filters,
      });
      setJobs(data);
    } catch (error) {
      setError("There was an error filtering jobs.");
    } finally {
      setLoading(false);
    }
  }
  const BASE_URL = process.env.NODE_ENV
    ? "https://api.thejobslist.io"
    : "http://localhost:3001";

  useEffect(() => {
    if (searchTerm) {
      getJobs(searchTerm);
    } else if (
      (filters.countries && filters.countries.length) ||
      (filters.skills && filters.skills.length) ||
      (filters.tags && filters.tags.length)
    ) {
      applyFilters(filters);
    } else {
      getJobs();
    }
  }, [currentPage, searchTerm, filters]);

  const PartitionCircle = () => (
    <div className="partition-circle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2"
        height="2"
        viewBox="0 0 2 2"
        fill="none"
      >
        <circle cx="1" cy="1" r="1" fill="#141414" fill-opacity="0.7" />
      </svg>
    </div>
  );

  const scrollToSection = () => {
    const sectionElement = document.getElementById("job-list");

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home-page">
      <div className="view-jobs" onClick={scrollToSection}>
        <span className="material-symbols-outlined" id="scroll-to-jobs">
          {hasScrolled ? "expand_less" : "expand_more"}
        </span>
      </div>
      <NavBar />
      <div className="headline-container">
        <HomeMain />
      </div>
      <div className="filter-container-wrapper">
        <form className="search-box" onSubmit={handleSearchSubmit}>
          <div className="search-filter">
            <span className="search-icon">
              <span className="material-symbols-outlined">search</span>
            </span>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search for a job..."
              className="search-filter-input"
            />
          </div>
        </form>
      </div>

      <div className="main-section-container">
{/*        <div ref={filterRef} className="common-filters-container">
          <div className="common-filters-wrapper">
            <div className="filter-heading">Filters</div>
            <div className="filters-cols">
              <div className="filter-container-wrapper">
                <div className="filter-name">Location</div>
                <Select
                  isMulti
                  name="countries"
                  options={countryOptions}
                  onChange={(options) =>
                    handleSelectChange("countries", options)
                  }
                  placeholder="Select countries..."
                />{" "}
              </div>

              <div className="filter-container-wrapper">
                {/* <div className="select-wrapper"> */}
                {/*<div className="filter-name">Skills</div>
                <Select
                  isMulti
                  name="skills"
                  options={skillOptions}
                  onChange={(options) => handleSelectChange("skills", options)}
                  placeholder="Select skills..."
                />
                {/* </div> */}
              {/*</div>
          </div>
          </div>
        </div> */} 
        <div className="jobs-list-container" id="job-list">
          <div className="jobs-panel-wrapper">
            {/* <div className="total-jobs">{jobs?.length} Jobs on this page</div> */}
            <div className="filter-toggle-button">
              <button onClick={toggleFilters}>
                <span className="material-symbols-outlined filter-icon">
                  filter_list
                </span>
                Filters
              </button>
            </div>
          </div>

          {jobs.map((job, index) => (
            <NewJobCard job={job} />
          ))}
          <div className="job-list-pagination-container">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
        <div className="email-container">
          <div className="subscription-container">
            <div className="filter-heading">📨 Email me for jobs</div>
            <div className="post-description">
              Get new jobs as soon as they're posted
            </div>
            {feedback && (
              <div className={`feedback ${feedback.type}`}>
                {feedback.message}
              </div>
            )}
            <form onSubmit={handleSubmitEmail}>
              <div className="email-input-wrapper">
                <div className="email-input-container">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@mail.com"
                    required
                  />
                </div>
              </div>
              {/* <div className="large-primar-btn"> */}
              <button type="submit" className="large-primar-btn">
                Subscribe
              </button>
              {/* </div> */}
            </form>
          </div>
          <ResumeUpload
            onUploadSuccess={() =>
              swal("Good job!", "Resume uploaded successfully!", "success")
            }
            // onUploadFailure={(errMsg) => alert("Upload failed: " + errMsg)}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
