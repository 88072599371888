import React, { useState } from "react";
import "./ResumeUploadWhileApply.css"; // If you want to style it separately
import { uploadFile } from "../../services/api";
import { ReactComponent as UploadIcon } from "./upload_icon.svg"; // Import an SVG for upload visualization

const ResumeUploadWhileApply = ({
  email,
  onUploadSuccess,
  onUploadFailure,
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState(null); // To display selected file name

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    // console.log("selectedFile.mimetype: ", selectedFile);
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        // File is more than 5MB
        setError("File size should be less than 5MB");
        setFile(null);
      } else if (
        ![
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(selectedFile.type)
      ) {
        setError("Only PDF and Word documents are allowed");
        setFile(null);
      } else {
        setError("");
        setFileName(selectedFile.name);
        setFile(selectedFile);
        await uploadSelectedFile(selectedFile);
      }
    }
  };

  const uploadSelectedFile = async (file) => {
    try {
      const response = await uploadFile({ file, email });
      if (response.status === 200 || response.status === 201) {
        onUploadSuccess(response.data);
      } else {
        onUploadFailure(response.data);
      }
    } catch (error) {
      onUploadFailure(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      try {
        const response = await uploadFile({ file, email }); // Make sure uploadFile returns a promise
        if (response.status === 200 || response.status === 201) {
          onUploadSuccess(response.data); // Assuming response.data holds relevant information
        } else {
          onUploadFailure(response.data); // Pass the error message or error details
        }
      } catch (error) {
        onUploadFailure(error.message);
      }
    }
  };

  return (
    <div className="resume-container">
      {file && <div className="selected-file-name">{fileName}</div>}
      {error && <div className="error-message">{error}</div>}

      <form onSubmit={handleSubmit}>
        <label className="large-primar-btn">
          <UploadIcon width="10" height="10" />
          Resume Upload
          <input
            type="file"
            style={{ display: "none" }} // hide the default input
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx" // Accept only PDF and Word files
          />
        </label>
      </form>
    </div>
  );
};

export default ResumeUploadWhileApply;
