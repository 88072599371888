// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./views/HomePage";
import JobPage from "./views/JobPage";
import Sitemap from "./components/Sitemap";
import TermsAndConditions from "./views/TermsConditions";
import PrivacyPolicy from "./views/PrivacyPolicy"; 
import ContactUs from "./views/ContactUs"; 

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/job/:jobAlias" element={<JobPage />} />
        <Route path="/sitemap.xml" element={<Sitemap />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </Router>
  );
};

export default App;