// src/views/ContactUs.js
import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import "./../styles/CommonPages.css";

const ContactUs = () => {
  return (
    <div className="page-container">
      <NavBar />
      <h2>Contact Us</h2>
      <p>
        If you have any questions, feedback, or concerns regarding our platform
        or services, please don't hesitate to reach out to us. You can contact
        us at:
      </p>
      <address>
        Email:{" "}
        <a href="mailto:support@thejobslist.io">support@thejobslist.io</a>
      </address>
      {/* You can continue to add more contact methods or details here as required */}
      <Footer />
    </div>
  );
};

export default ContactUs;
