// src/views/PrivacyPolicy.js
import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import "./../styles/CommonPages.css";

const PrivacyPolicy = () => {
  return (
    <div className="page-container">
      <NavBar />
      <h2>Privacy Policy</h2>
      <p>
        At TheJobsList.io, accessible from our website, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by
        TheJobsList.io and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <h3>Personal Data Collection</h3>
      <p>
        We may collect personal information from you such as your name, email
        address, and job preferences. We only collect information directly from
        you and use this information to provide our services.
      </p>
      <h3>Use of Data</h3>
      <p>
        We use the collected data to: Provide and maintain our service. Notify
        you about changes to our service. Allow you to participate in
        interactive features of our service when you choose to do so.
      </p>
      <h3>Data Protection</h3>
      <p>
        We adopt secure data collection, storage, and processing practices to
        protect against unauthorized access, alteration, disclosure, or
        destruction of your personal information.
      </p>
      <h3>Third-Party Services</h3>
      <p>
        We do not employ third-party companies and individuals to facilitate our
        service.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions, feedback, or concerns regarding our platform
        or services, please don't hesitate to reach out to us. Email:
        support@thejobslist.io
      </p>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
