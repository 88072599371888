import React, { useState } from "react";
import { subscribeEmailApi } from "../../services/api";
import { Link, useLocation } from "react-router-dom";

const MockInterviewForm = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState(null); // For storing feedback
  const url = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await subscribeEmailApi({
        email,
        url: url.pathname,
        navBar: true,
        name,
      });
      if (response) {
        localStorage.setItem("userEmail", email);
        setFeedback({
          type: "success",
          message: "We'll email you to confirm soon.",
        });
        setTimeout(onClose, 2000); // Optionally, close the modal after a 2-second delay
      } else {
        setFeedback({
          type: "error",
          message: "Subscription failed.",
        });
      }
    } catch (error) {
      setFeedback({
        type: "error",
        message: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <div className="modal-form">
      <h2>Master Your Interviews!</h2>
      <p>
        Let's simulate a real interview environment. Share your details and get
        a mock interview scheduled with industry veterans to hone your skills.
      </p>
      {feedback && (
        <div className={`feedback ${feedback.type}`}>{feedback.message}</div>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your Name"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit">Subscribe</button>
      </form>
    </div>
  );
};

export default MockInterviewForm;
